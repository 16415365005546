import {
  collection,
  query,
  getDocs,
  orderBy,
  where,
} from 'firebase/firestore'
import { db } from '@/main'
import Resource from '@/data/tools/Resource'

export default class PageDao{
  async getPages(id_story: String, id_chapter: string, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters/${id_chapter}/pages`),
        where('isDeleted', '==', false),
        orderBy('numberPage', 'asc')
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getPage(id_story: String, id_chapter: String, numberPage: number, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters/${id_chapter}/pages`),
        where('numberPage', '==', numberPage)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }
}