import {
  collection,
  query,
  getDoc,
  getDocs,
  orderBy,
  where,
  onSnapshot,
  limit,
  doc,
  startAfter,
} from 'firebase/firestore'
import { db } from '@/main'
import Resource from '@/data/tools/Resource'

export default class ChapterDao{

  first: any
  last: any

  async getChapters(id_story: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters`),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('orderNumber', 'asc'),
        limit(8)
      )
      onSnapshot(q, (snap) => {
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getFirstChapter(id_story: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters`),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('orderNumber', 'asc'),
        limit(1)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getLastChapter(id_story: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters`),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('orderNumber', 'desc'),
        limit(1)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getNextChapters(id_story: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters`),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('orderNumber', 'asc'),
        startAfter(this.last),
        limit(8)
      )
      onSnapshot(q, (snap) => {
        this.first = snap.docs[0]
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getBackChapters(id_story: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, `stories/${id_story}/chapters`),
        where('isDeleted', '==', false),
        where('isVisible', '==', true),
        orderBy('orderNumber', 'desc'),
        startAfter(this.first),
        limit(8)
      )
      onSnapshot(q, (snap) => {
        this.last = snap.docs[0]
        this.first = snap.docs[snap.docs.length - 1]
        const result = snap.docs.reverse()
        callback(Resource.success(result.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getChapter(id_story: String, id_chapter: String, callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = doc(db, `stories/${id_story}/chapters`, `${id_chapter}`)
      const snap = await getDoc(q)
      callback(Resource.success(snap.data()))
    } catch (error) {
      callback(Resource.error(error))
    }
  }
}